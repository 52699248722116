import React, { useContext } from 'react';
import { Container, HeadingsRed} from 'components/common';
import { Headings } from 'components/common';
import { RedLine } from 'components/common';
import { Wrapper, IntroWrapper, Details,LinksWrapper } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 
import doc0 from 'assets/documents/0.pdf';
import doc1 from 'assets/documents/1.pdf';
import doc2 from 'assets/documents/2.pdf';
import doc3 from 'assets/documents/3.pdf';
import doc4 from 'assets/documents/4.pdf';
import doc5 from 'assets/documents/5.pdf';
import doc6 from 'assets/documents/6.pdf';
import doc7 from 'assets/documents/7.pdf';
import doc8 from 'assets/documents/8.pdf';
import doc9 from 'assets/documents/9.pdf';
import doc10 from 'assets/documents/10.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '40vw',
    [`@media (max-width: 960px)`]: {
      width: '100vw'}
  },
  text: {
    marginBottom: 15,
  },
  documentColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '16px',
    paddingLeft: '16px'
  }
 
}));

export const Document = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
          <div>
            <Headings style={{width: '100%'}}> 
            <RedLine />
            Декларации
          </Headings>
          <LinksWrapper>     
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.documentColumn}>
            <a href = {doc0} target = "_blank" className={classes.text}>
               Сертификат о <b>взрывозащите</b>
            </a>

            <a href = {doc1} target = "_blank" className={classes.text}>
               Декларация о соответствии <b> устройств по сбору и передаче информации</b>
            </a>

            <a href = {doc7} target = "_blank" className={classes.text}>
              Декларация о соответствии <b>беспроводного программатора</b>
            </a>

            <a href = {doc5} target = "_blank" className={classes.text}>
              Декларация о соответствии <b>считывающих устройств модели Nozzle Reader</b>
            </a>

            <a href = {doc2} target = "_blank" className={classes.text}>
              Декларация о соответствии <b>средств электросвязи 1</b>
            </a>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.documentColumn}>              
            <a href = {doc3} target = "_blank" className={classes.text}>
            Декларация о соответствии <b>средств электросвязи 2</b>
            </a> 

            <a href = {doc6} target = "_blank" className={classes.text}>
            Декларация о соответствии <b>средств электросвязи 3</b>
            </a>

            <a href = {doc4} target = "_blank" className={classes.text}>
               Декларация о соответствии <b>платёжных терминалов 1</b>
            </a>

            <a href = {doc10} target = "_blank" className={classes.text}>
               Декларация о соответствии <b>платёжных терминалов 2</b>
            </a>

             <a href = {doc8} target = "_blank" className={classes.text}>
            Декларация о соответствии <b>устройств для сбора информации о транспортном средстве</b>
            </a>

            <a href = {doc9} target = "_blank" className={classes.text}>
            Декларация о соответствии <b>беспроводных терминалов</b>
            </a>
            
            </Grid>
          </Grid>       
          </LinksWrapper>


         </div>     
       
      </IntroWrapper>          
    </Wrapper>
  );
};
