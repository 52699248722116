import React from "react";

import { makeStyles, Modal, Grid, Typography } from "@material-ui/core";
import { ButtonRed } from "components/common";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "75px auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    width: "58.54vw",
    "@media(min-width: 1280px)": {
      width: "750px",
    },
    "@media(max-width: 960px)": {
      width: "90%",
    },

    "& label": {
      width: "100%",
      flexShrink: 0,
      display: "block",
      marginTop: "20px",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: 1.5,
      color: "#222222",
      "& b": {
        color: "#DE7D76",
      },
      "&:first-child": {
        marginTop: 0,
      },
    },

    "& input": {
      boxSizing: "border-box",
      border: `3px solid #E5E5E5`,
      background: "transparent",
      outline: "none",

      "&:-webkit-autofill": {
        "-webkit-text-fill-color": "#000",
        "-webkit-box-shadow": `0 0 0 30px #fff inset !important`,
        caretColor: "#000",
      },

      marginTop: "8px",
      width: "100%",
      height: 65,
      fontSize: "20px",
      lineHeight: 1.5,
      padding: "17.5px 10px",

      "&::placeholder": {
        fontWeight: "normal",
        color: "#828282",
      },
    },
    "& input.important": {
      borderColor: "#F6D7DA",
    },

    "& textarea": {
      boxSizing: "border-box",
      border: `3px solid #E5E5E5`,
      background: "transparent",
      outline: "none",

      "&:-webkit-autofill": {
        "-webkit-text-fill-color": theme.palette.primary.white,
        "-webkit-box-shadow": `0 0 0 30px ${theme.palette.background.main} inset !important`,
        caretColor: theme.palette.primary.white,
      },

      marginTop: "8px",
      width: "100%",
      height: 251,
      fontSize: "20px",
      lineHeight: 1.5,
      padding: "15px 10px",

      "&::placeholder": {
        fontWeight: "normal",
        color: "#828282",
      },
    },
  },
  checkbox: {
    display: "none",

    "& + label": {
      display: "inline-flex",
      alignItems: "center",
      userSelect: "none",
      cursor: "pointer",

      fontWeight: 400,
      fontSize: 18,
      lineHeight: "27px",
    },
    "& + label::before": {
      content: '""',
      display: "inline-block",
      boxSizing: "border-box",
      width: 35,
      height: 35,
      flexShrink: 0,
      flexGrow: 0,
      border: "3px solid #222222",
      marginRight: 10,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "77%",
    },
    "&:checked + label::before": {
      backgroundImage: 'url("/svg/checkbox.svg")',
    },
  },
  accentText: {
    "& + label": {
      fontWeight: 700,
      fontSize: 18,
      color: "#00559B",
      textDecoration: "underline",
    },
  },
  description: {
    maxWidth: "895px !important",
    marginTop: "10px !important",
    marginBottom: "0 !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "21px !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
  },
  modalContent: {
    width: "30vw",
    margin: "auto",
    padding: "1.56vw",
    outline: "none",
    background: "#fff",
    borderRadius: "1.56vw",
    "@media(min-width: 1280px)": {
      width: "384px",
      padding: "30px",
      borderRadius: "30px",
    },
    "@media(max-width: 767px)": {
      width: "82vw",
      padding: "3vw",
      borderRadius: "3vw",
    },
  },
  modalContentText: {
    fontSize: "1.87vw",
    "@media(min-width: 1280px)": {
      fontSize: "24px",
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
    },
  },
  error: {
    width: "100%",
    marginRight: "0.8em",
    color: "red",

    fontSize: "1.4vw",
    "@media(min-width: 1280px)": {
      fontSize: "18px",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  buttonRed: {
    marginTop: 75,
    borderColor: "transparent",

    "@media(min-width: 960px)": {
      "&:hover": {
        color: " #C51B10",
        transform: " scale(1.1)",
      },
    },
  },
  errorMessage: {
    width: "100%",
    marginTop: "1em",

    fontSize: "1.4vw",
    "@media(min-width: 1280px)": {
      fontSize: "18px",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  sendMessage: {
    fontWeight: 700,

    fontSize: "2.81vw",
    "@media(min-width: 1280px)": {
      fontSize: "36px",
    },
    "@media(max-width: 767px)": {
      fontSize: "5.79vw",
    },
  },
}));

export default function ContactForm() {
  const classes = useStyles();

  const [errors, setErrors] = React.useState(false);
  const [sendData, setSendData] = React.useState(false);

  function setBorderColor(elem, flag) {
    if (flag) elem.style.borderColor = "#F6D7DA";
    else elem.style.borderColor = "#C51B10";
  }

  function validationName(e) {
    setBorderColor(
      e.target,
      e.target.value !== "" && /^[a-zа-яё\s]+$/i.test(e.target.value)
    );
  }
  function validationEmail(e) {
    setBorderColor(e.target, /@/.test(e.target.value));
  }
  function validationPhone(e) {
    setBorderColor(
      e.target,
      /^\s*(\+?[78]-?\(?\d{3}\)?-?)?\d{3}-?\d{2}-?\d{2}\s*$/.test(
        e.target.value.replace(/\s/g, "")
      )
    );
  }
  function validationCompanyName(e) {
    setBorderColor(e.target, e.target.value !== "");
  }

  function submit(e) {
    e.preventDefault();
    const form = e.nativeEvent.target;
    const full_name = form.full_name.value;
    const email = form.email.value;
    const phone = form.phone.value;
    const company_name = form.company_name.value;
    const INN = form.INN.value;
    const amount_point = form.amount_point.value;
    const amount_car = form.amount_car.value;
    const other_info = form.other_info.value;
    const privacy_policy = form.privacy_policy.checked;
    const consent_presentation = form.consent_presentation.checked;

    const errorsArr = [];
    if (full_name === "")
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - введите ФИО
        </Typography>
      );
    if (!/^[a-zа-яё\s]+$/i.test(full_name))
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - недопустимые символы в ФИО
        </Typography>
      );
    if (!/@/.test(email))
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - некорректный e-mail
        </Typography>
      );
    if (
      !/^\s*(\+?[78]-?\(?\d{3}\)?-?)?\d{3}-?\d{2}-?\d{2}\s*$/.test(
        phone.replace(/\s/g, "")
      )
    )
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - некорректный номер телефона
        </Typography>
      );
    if (company_name === "")
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - введите название компании
        </Typography>
      );
    if (!privacy_policy)
      errorsArr.push(
        <Typography className={classes.error} align="left">
          - подтвердите согласие с обработкой персональных данных
        </Typography>
      );

    if (errorsArr.length) {
      setErrors(errorsArr);
      setTimeout(setErrors, 5000, false);
    } else {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const body = JSON.stringify({
        full_name,
        email,
        phone,
        company_name,
        INN,
        amount_point,
        amount_car,
        other_info,
        consent_presentation: consent_presentation ? "да" : "нет",
      });
      const init = {
        method: "POST",
        headers,
        body,
      };

      const url = new URL(window.location);
      fetch(`${url.origin}/.netlify/functions/email_server`, init).then(
        (res) => {
          if (res.status === 200 || res.status === 502) {
            setSendData(true);
            form.reset();
            setTimeout(setSendData, 2000, false);
          } else {
            const message = (
              <Typography className={classes.errorMessage} align="center">
                Что-то пошло не так, сообщите нам о проблеме{" "}
                <a
                  href="mailto:sales-cis@gilbarco.com"
                  style={{ color: "#C51B10" }}
                >
                  sales-cis@gilbarco.com
                </a>
              </Typography>
            );
            setErrors(message);
          }
        }
      );
      //   const dataForm = `
      //   ФИО: ${full_name}
      //   E-mail: ${email}
      //   Телефон: ${phone}
      //   Название компании: ${company_name}
      //   ИНН: ${INN}
      //   Количество точек выдачи топлива: ${amount_point}
      //   Количество транспортных средств: ${amount_car}
      //   Дополнительная информация: ${other_info}
      //   Согласие на онлайн-презентацию: ${consent_presentation}
      // `;
      //   console.log(window.ga);
      //   window.ga(
      //     "send",
      //     "event",
      //     "отправка данных из формы",
      //     "отправлены",
      //     dataForm
      //   );
    }
  }

  return (
    <form className={classes.form} onSubmit={submit}>
      <label>
        ФИО<b>*</b>
        <input
          placeholder="ФИО"
          name="full_name"
          onInput={validationName}
          className="important"
        />
      </label>

      <label>
        Адрес электронной почты<b>*</b>
        <input
          placeholder="Адрес электронной почты"
          name="email"
          onInput={validationEmail}
          className="important"
        />
      </label>

      <label>
        Номер телефона<b>*</b>
        <input
          placeholder="Номер телефона"
          name="phone"
          onInput={validationPhone}
          className="important"
        />
      </label>

      <label>
        Название компании<b>*</b>
        <input
          placeholder="Название компании"
          name="company_name"
          onInput={validationCompanyName}
          className="important"
        />
      </label>

      <label>
        ИИН
        <input placeholder="ИИН" name="INN" />
      </label>

      <label>
        Кол-во точек выдачи топлива
        <input placeholder="Кол-во точек выдачи топлива" name="amount_point" />
      </label>

      <label>
        Кол-во транспортных средств
        <input placeholder="Кол-во транспортных средств" name="amount_car" />
      </label>

      <label>
        Дополнительная информация
        <textarea placeholder="Введите ваше сообщение" name="other_info" />
      </label>

      <input
        type="checkbox"
        id="privacy_policy"
        name="privacy_policy"
        className={classes.checkbox}
      />
      <label for="privacy_policy">
        Согласие на обработку персональных данных
      </label>

      <input
        type="checkbox"
        id="consent_presentation"
        name="consent_presentation"
        className={classes.checkbox + " " + classes.accentText}
      />
      <label for="consent_presentation">Хочу получить онлайн-презентацию</label>
      <p className={classes.description}>
        Записываясь на онлайн-презентацию, вы подтверждаете заинтересованность в
        проведении удалённого conference-звонка, детальном показе продукта и
        ответа на ваши вопросы
      </p>

      <ButtonRed className={classes.buttonRed}>Оставить заявку</ButtonRed>

      {errors ? (
        <Modal
          open={errors}
          onClose={() => setErrors(false)}
          className={classes.modal}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.modalContent}
          >
            <Typography align="center" className={classes.modalContentText}>
              Данные не отправлены
            </Typography>
            {errors}
          </Grid>
        </Modal>
      ) : null}

      {sendData ? (
        <Modal
          open={sendData}
          onClose={() => setSendData(false)}
          className={classes.modal}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.modalContent}
          >
            <Typography align="center" className={classes.sendMessage}>
              Данные успешно отправлены
            </Typography>
          </Grid>
        </Modal>
      ) : null}
    </form>
  );
}
