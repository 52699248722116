import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 8rem;
`;

export const IntroWrapper = styled.div`
  // padding: 4rem 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 100px;
    margin-bottom: -20px;
    font-weight: 800;
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
    font-family: 'Montserrat';

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 45px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => (theme === 'light' ? '#222222' : '#ffffff')};
    font-family: 'Montserrat';

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    margin-left: 30px;
    padding-top: 15px;
    max-width: 500px;
    max-height: 170px;
  }

  @media (max-width: 960px) {
   
  }

  @media (max-width: 680px) {

  }
`;
