import styled from 'styled-components';
import redhalf from 'assets/illustrations/redhalf.png';
import redhalffull from 'assets/illustrations/redhalffull.png';

export const Wrapper = styled.div`
display: none;

@media (max-width: 770px) {
  display: block;
  margin-bottom: 64px;
}
`;

export const IntroWrapper = styled.div`
  // padding: 4rem 0;
  display: flex;
  align-items: center;
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 100px;
    margin-bottom: -20px;
    font-weight: 800;
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
    font-family: 'Montserrat';

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 40px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => (theme === 'light' ? '#222222' : '#ffffff')};
    font-family: 'Montserrat';

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }
`;

export const LinksWrapper = styled.div`

display: flex;
flex-direction: column;

s
  h2,
  a,
  span {
  color: #C51B10;
  text-decoration: none;
  font-weight: 400;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
    flex-direction: column;
    max-width: 90%;
  }
`