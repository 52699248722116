import styled from "styled-components";
import background from "assets/illustrations/background.svg";

export const Wrapper = styled.div`
  padding-bottom: 8rem;
  background-size: contain;
  background-position: left top;
  background-repeat-x: repeat;
  background-repeat-y: no-repeat;

  @media (max-width: 960px) {
    background-size: cover;
    background-position-x: center;
    padding-bottom: 0rem;
  }

  a {
    font-weight: 700;
    font-family: "Montserrat";
  }

  @media (min-width: 1770px) {
    background-size: contain;
    background-position: center top;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
  }
`;

export const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 90%;
  }
`;

export const Details = styled.div`
  flex: 1;
  width: 100%;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;

    & > * {
      margin-left: 1rem;
    }
  }

  h1 {
    font-size: 100px;
    margin-bottom: 5px;
    font-weight: 800;
    color: "#000000 !important";
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: "#000";
      color: "#000000 !important";
    }

    @media (max-width: 680px) {
      font-size: 45px;
    }
  }

  h4 {
    color: "#000000 !important";
    margin-top: 30px;
    font-size: 24px;
    font-weight: 600;
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: "#000";
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    margin-left: 30px;
    max-width: 500px;
    max-height: 170px;
  }

  @media (max-width: 960px) {
  }

  @media (max-width: 680px) {
  }
`;

export const IntroMobile = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    font-size: 42px;
    line-height: 70px;
  }

  img {
    @media (max-width: 960px) {
      width: 80vw;
      margin: 0;
    }
  }
`;

export const IntroDesktop = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;
