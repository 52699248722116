import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
  RedLine,
} from "../../../common";
import { ButtonMore } from "../../../common";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "../Modal";
import equip2_modal from "../../../../assets/illustrations/equip2_modal.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: "450px",
    height: "640px",
    marginRight: "15px",
  },
  cardText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardImage: {
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    height: "250px",
    display: "flex",
    justifyContent: "center",
  },
  cardHide: {
    maxWidth: "400px",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: 0,
    transition: "all .7s ease-in-out",

    fontFamily: "Roboto, Helvetica, sans-serif",
    fontWeight: "300",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
    alignItems: "left",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      // display: 'none',
      zIndex: 1,
    },
  },
  imageHide: {
    margin: 0,
    width: "100%",
    height: "105%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    transform: "scaleY(1)",
    transition: " all .7s ease-in-out",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      // display: 'none',
      opacity: 0,
      zIndex: -10,
    },
  },
  modalWindow: {
    width: "50vw",
    height: "fit-content",
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "30px",
    fontFamily: "Roboto",
    fontWeight: "400",
    zIndex: 1000,
    position: "absolute",
  },
  modalWindowWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddintTop: "100px",
    position: "relative",
  },
  cardOneRightImage: {
    // position: 'absolute',
    width: "100%",
    maxHeight: "400px",
    transition: " all .7s ease-in-out",
    transform: "scaleY(1)",
    backgroundColor: "#f3f3f3",
    paddingLeft: "35px",
    "&:hover": {
      // opacity: 0,
      zIndex: -10,
    },
  },
  cardOneRightImageItem: {
    maxHeight: "400px",
    width: "100%",
  },
  closeButton: {
    display: "flex",
    width: "100%",
    marginLeft: "auto",
    paddingBottom: 20,
  },
  extended: {
    marginLeft: "auto",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  modalImage: {
    paddingTop: 50,
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip2.png" }) {
        childImageSharp {
          fixed(height: 250, width: 210) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        style={{
          backgroundColor: "#f3f3f3",
          padding: "10px 0 10px 10px",
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={6} className={classes.cardText}>
          <NumberRed>02.</NumberRed>
          <Headings2>Считыватель RFID-меток</Headings2>
        </Grid>
        <Grid item xs={6} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <span>
              Считыватель RFID-меток, устанавливается на каждый заправочный
              пистолет топливораздаточной колонки.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                height="250"
                width="210"
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
              />
              <img
                height="250"
                width="210"
                src={data.file.childImageSharp.fixed.src}
                alt="Компонент 2"
              />
            </picture>
            {/* <img src={equip2} className={classes.imageHide} alt="Компонент 2"/> */}
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Modal>
          <RedLine />
          <Headings2 style={{ fontSize: "34px" }}>
            Считыватель RFID-меток
          </Headings2>
          <span>
            <b>Считыватель RFID-меток</b>, устанавливается на каждый заправочный
            пистолет топливораздаточной колонки.
          </span>
          {/* <img
            src={equip2_modal}
            alt="equip_modal"
            className={classes.modalImage}
          ></img> */}
          <br />
          <br />
          <b>RFID считывающее устройство</b> состоит из:
          <br />
          <br />
          • Автономный компактный транспондер <br />
          • Срок службы батареи ~3 года <br />
          • Светодиодная индикация <br />
          • Чрезвычайно прочный и надежный корпус <br />
          • Эффективная защита от демонтажа
          <br />
          • Подходит для всех типов сопел, держателей и входных отверстий в баки
          транспортных средств <br />
          • Инновационный механизм крепления
          <br /> <br />
          Качество подтвеждено сертификатами: ATEX, FCC, UL и российским
          сертификатом, подтверждающим применение на взрывоопасных зонах.
          <br />
        </Modal>
      </Backdrop>
    </div>
  );
};
