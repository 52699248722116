import React, { useContext } from 'react';
import { Container, HeadingsRed, NumberRed, Headings2} from '../../../common';
import { ButtonMore } from '../../../common';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import equip6 from '../../../../assets/illustrations/equip6.png';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '../../../../assets/icons/closeicon.png';
import { useStaticQuery, graphql }from "gatsby";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: '450px',
    height: '250px',
    marginRight: '15px',
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  cardImage: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    height: '260px',
    display: 'flex',
    justifyContent: 'center'
  },
  cardHide: {
    maxWidth: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    transition: 'all .7s ease-in-out',

    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontWeight: '300',

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '15px',
    alignItems: 'left',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      // display: 'none',
    zIndex:1}

  },
  imageHide: {
    margin: 0,
    width: '120%',
    height: '120%',
    zIndex: 1,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   position: 'relative',
   transform: 'scaleY(1)',
   transition:' all .7s ease-in-out',
   backgroundColor: '#f3f3f3',
   '&:hover': {
    // display: 'none',
    opacity: 0,
  zIndex:-10}
  },
  modalWindow: {
    width: '50vw',
    height: 'fit-content',
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    zIndex: 1000,
    position: 'absolute'
  },
  modalWindowWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddintTop: '100px',
    position:'relative'
  },
  cardOneRightImage: {
    // position: 'absolute',
    width: '100%',
    maxHeight: '220px',
    transition:' all .7s ease-in-out',
    transform: 'scaleY(1)',
    backgroundColor:'#f3f3f3',
    paddingLeft: '35px',
    '&:hover': {
      // opacity: 0,
      zIndex:-10
    }
  },
  cardOneRightImageItem: {
    maxHeight: '260px',
    width: '100%',
  },
  closeButton: {
    display:'flex',
    width: '100%',
    marginLeft: 'auto',
    paddingBottom: 20,
  },
  extended: {
    marginLeft: 'auto',
    '&:hover': {
     transform: 'scale(1.2)',
    },
  }  

}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
    
  };

  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "equip6.png"}) {
      childImageSharp {
        fixed (width: 270, height: 270 ) {
          src
          srcWebp
        }
      }
    }
  }
`)


  return (
    <Grid container style={{backgroundColor: '#f3f3f3', padding: '0px 0 0px 10px', maxHeight: '260px', clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`}}>
    <Grid item xs={6} className={classes.cardText}>
      <NumberRed>06.</NumberRed>
      <Headings2>Программатор</Headings2>
    </Grid>
    <Grid item xs={6} className={classes.cardImage}>
     
     <div className={classes.cardHide}>
       <span>Данное устройство позволяет программировать метки для всех транспортных средств (данные, идентификатор транспортного средства).</span>
        </div>
        <div className={classes.cardOneRightImage}>
        <picture>
          <source
            height="270" width="270"
            srcSet={data.file.childImageSharp.fixed.srcWebp}
            type="image/webp"
          />
          <img
             height="270" width="270"
            src={data.file.childImageSharp.fixed.src}
            alt="Компонент 6"
          />
      </picture>
      {/* <img src={equip6} className={classes.imageHide} alt="Компонент 6"/> */}
          </div>
    </Grid>
  </Grid>
  );
};
