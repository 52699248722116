import React, { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { HeadingsRed } from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import { Wrapper, IntroWrapper } from "./styles";
import {
  colors,
  CssBaseline,
  Typography,
  Container,
  makeStyles,
  createMuiTheme,
  Checkbox,
  TextField,
  Grid,
  Slider,
  Switch,
  Button,
} from "@material-ui/core";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#C51B10",
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: "#fff",
    },
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  grid: {
    width: "100%",
    margin: 0,
  },
  gridcolumnSummary: {
    backgroundColor: "#f3f3f3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30px !important",
    clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
    [`@media (max-width: 960px)`]: {
      paddingLeft: "0 !important",
      marginLeft: "0px",
      marginRight: "0px",
    },

    [`@media (max-width: 680px)`]: {
      paddingLeft: "0 !important",
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  lightBulb: {
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  h5: {
    color: "#000000",
    fontSize: "20px",
  },
  h3: {
    color: "#000",
    fontSize: "20px",
  },
  h2: {
    color: "#222222",
    fontSize: "45px",
    marginBottom: "30px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
      marginBottom: "10px",
    },
  },
  numberEcomy: {
    color: "#C51B10",
    fontSize: "50px",
    fontWeight: 600,
    marginBottom: "50px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
      marginBottom: "10px",
      marginTop: "0px",
    },
  },
  TextField: {
    color: "#222222",
    fontSize: "16px",
    marginTop: "10px",
  },
  slider: {
    margin: "10px",
  },
  item: {
    margin: "15px 15px 15px 0",
    [`@media (max-width: 960px)`]: {
      margin: "15px",
    },
  },
  GPStracking: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Calculator = () => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    costOil: 200,
    // costOil: 200,
    turnOver: 500000,
    pointOil: 4,
    cars: 300,
    GPStracking: true,
    workers: 8,
    // costProject: 1700000,
    // costWorkers: 35000,

    //Казахстанский тенге
    costProject: 10380733,
    costWorkers: 213720,
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeSlider = (event, value, name) => {
    setValues({ ...values, [name]: value });
  };

  const [checked, setChecked] = React.useState(false);
  const checkedChange = () => {
    setChecked(!checked);
    setValues({
      costOil: values.costOil,
      turnOver: values.turnOver,
      pointOil: values.pointOil,
      cars: values.cars,
      GPStracking: !checked,
      workers: values.workers,
      costProject: values.costProject,
      costWorkers: values.costWorkers,
    });
  };

  const costPrject = values.costProject * values.pointOil + values.cars * 7000;
  const costWorkers = values.costWorkers * values.workers;
  const lostManey =
    (values.turnOver * values.costOil * (values.GPStracking ? 0.04 : 0.15)) /
    (1 + (1 / values.cars) * 10);
  const economy = lostManey + costWorkers;
  const payback = costPrject / economy;

  return (
    <Wrapper id="calculator">
      <IntroWrapper>
        <Headings>
          <RedLine />
          Рассчитайте{" "}
          <HeadingsRed className={classes.redText}>свою</HeadingsRed> экономию
        </Headings>
        <Container className={classes.root}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12} sm={6} className={classes.gridcolumn}>
              <div className={classes.item}>
                <div className={classes.h5}>Цена за литр бензина, &#8376;</div>
                <div className={classes.TextField}>
                  {Intl.NumberFormat("ru-RU", {
                    maximumFractionDigits: 0,
                  }).format(values.costOil)}{" "}
                  &#8376;
                </div>
                <Slider
                  value={values.costOil}
                  min={100}
                  step={1}
                  max={400}
                  onChange={(event, value) =>
                    handleChangeSlider(event, value, "costOil")
                  }
                  aria-label="non-linear-slider"
                  aria-checked="mixed"
                  // aria-input-field-name="text"
                  style={{ color: "#C51B10" }}
                />
              </div>

              <div className={classes.item}>
                <div className={classes.h5}>Оборот топлива (в мес.), л</div>
                <div className={classes.TextField}>
                  {Intl.NumberFormat("ru-RU", {
                    maximumFractionDigits: 0,
                  }).format(values.turnOver)}{" "}
                  л.
                </div>
                <Slider
                  value={values.turnOver}
                  min={100000}
                  step={100000}
                  max={5000000}
                  onChange={(event, value) =>
                    handleChangeSlider(event, value, "turnOver")
                  }
                  aria-label="non-linear-slider"
                  aria-checked="mixed"
                  // aria-input-field-name="text"
                  style={{ color: "#C51B10" }}
                />
              </div>
              <div className={classes.item}>
                <div className={classes.h5}>
                  Кол-во точек выдачи топлива (АЗС, АТЗ)
                </div>
                <div className={classes.TextField}>
                  {Intl.NumberFormat("ru-RU", {
                    maximumFractionDigits: 0,
                  }).format(values.pointOil)}{" "}
                  ед.
                </div>
                <Slider
                  value={values.pointOil}
                  min={1}
                  step={1}
                  max={100}
                  onChange={(event, value) =>
                    handleChangeSlider(event, value, "pointOil")
                  }
                  aria-label="non-linear-slider"
                  aria-checked="mixed"
                  // aria-input-field-name="text"
                  style={{ color: "#C51B10" }}
                />
              </div>
              <div className={classes.item}>
                <div className={classes.h5}>Кол-во транспортных средств</div>
                <div className={classes.TextField}>
                  {Intl.NumberFormat("ru-RU", {
                    maximumFractionDigits: 0,
                  }).format(values.cars)}{" "}
                  шт.
                </div>
                <Slider
                  value={values.cars}
                  min={50}
                  step={1}
                  max={5000}
                  onChange={(event, value) =>
                    handleChangeSlider(event, value, "cars")
                  }
                  aria-label="non-linear-slider"
                  aria-checked="mixed"
                  // aria-input-field-name="text"
                  style={{ color: "#C51B10" }}
                />
              </div>
              <div className={classes.item}>
                <div className={classes.h5}>
                  Есть ли GPStracking?
                  <div className={classes.GPStracking}>
                    <div
                      onClick={() => checkedChange()}
                      style={{
                        // color: checked ? '#000' : '#fff',
                        cursor: "pointer",
                        background: checked ? "#C51B10" : "#F3F3F3",
                        width: "fit-content",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        marginRight: "7px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        color: checked ? "white" : "grey",
                        cursor: "pointer",
                      }}
                    >
                      Да
                    </div>
                    <div
                      onClick={() => checkedChange()}
                      style={{
                        color: checked ? "grey" : "white",
                        cursor: "pointer",
                        // color: checked ? '#fff' : '#000',
                        background: checked ? "#F3F3F3" : "#C51B10",
                        width: "fit-content",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        marginRight: "7px",
                      }}
                    >
                      Нет
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.h5}>
                  Количество операторов АЗС (водителей АТЗ)
                </div>
                <div className={classes.TextField}>
                  {Intl.NumberFormat("ru-RU", {
                    maximumFractionDigits: 0,
                  }).format(values.workers)}{" "}
                  чел.
                </div>
                <Slider
                  value={values.workers}
                  min={1}
                  step={1}
                  max={100}
                  onChange={(event, value) =>
                    handleChangeSlider(event, value, "workers")
                  }
                  aria-label="non-linear-slider"
                  // aria-input-field-name="text"
                  aria-checked="mixed"
                  style={{ color: "#C51B10" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridcolumnSummary}>
              <div className={classes.summary}>
                <div className={classes.item}>
                  <div className={classes.h2}>Экономия</div>
                  <div className={classes.h3}>
                    Потери в месяц, воровство топлива, некачественный учет,
                    ошибки персонала
                  </div>
                  <div className={classes.numberEcomy}>
                    ~
                    {Intl.NumberFormat("ru-RU", {
                      maximumFractionDigits: 0,
                    }).format(lostManey)}{" "}
                    &#8376;
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={classes.h2}>Окупаемость проекта</div>
                  <div className={classes.h3}>
                    Через столько месяцев, экономия превратится в дополнительную
                    прибыль
                  </div>
                  <div className={classes.numberEcomy}>
                    ~
                    {Intl.NumberFormat("ru-RU", {
                      maximumFractionDigits: 1,
                    }).format(payback)}{" "}
                    мес.
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </IntroWrapper>
    </Wrapper>
  );
};
