import React, { useContext } from 'react';
import { Container, HeadingsRed} from 'components/common';
import { Wrapper, IntroWrapper, Details } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../../assets/icons/closeicon.png';

const useStyles = makeStyles((theme) => ({
  modalWindow: {
    width: '60vw',
    maxHeight: '700px',
    overflowY:'auto',
    marginTop: '70px',
    backgroundColor: 'white',
    color: 'black',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontWeight: '300',
    zIndex: 1000,
    position: 'absolute'
  },
  modalWindowWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddintTop: '100px',
    position:'relative'
  },

  closeButton: {
    display:'flex',
    width: '100%',
    marginLeft: 'auto',
    paddingBottom: 10,
  },
  extended: {
    marginLeft: 'auto',
    '&:hover': {
     transform: 'scale(1.2)',
    },

  }
}));

export default ({children}) => {
  const classes = useStyles();

  return (
    <Wrapper>
       <div className={classes.modalWindowWrapper} >
       <div className={classes.modalWindow}>
       <div className={classes.closeButton}><img className={classes.extended} src={CloseIcon} alt="Кнопка закрыть"/></div>
       {children}
        </div>
        </div> 
    </Wrapper>
  );
};
