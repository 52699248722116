import React, { useContext } from 'react';
import { Container, HeadingsRed, NumberRed, Headings2} from 'components/common';
import { Headings } from 'components/common';
import { RedLine } from 'components/common';
import { Wrapper, IntroWrapper } from './styles';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Equip1Mob from './Equip1Mob'
import Equip2Mob from './Equip2Mob'
import Equip3Mob from './Equip3Mob'
import Equip4Mob from './Equip4Mob'
import Equip5Mob from './Equip5Mob'
import Equip6Mob from './Equip6Mob'
import Equip7Mob from './Equip7Mob'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    // maxHeight: '250px'
  },

}));

export default () => {
  const classes = useStyles();

  return (
          <Grid container>
            <Grid style={{ height: '250px'}} className={classes.paper} item xs={12} sm={12}>
              <Equip1Mob />
            </Grid>
            <Grid style={{ height: '260px'}} className={classes.paper} item xs={12} sm={12}>
              <Equip2Mob />
            </Grid>
            <Grid className={classes.paper} item xs={12} sm={12}>
              <Equip3Mob />
            </Grid>
            <Grid style={{ height: '260px'}}className={classes.paper} item xs={12} sm={12}>
              <Equip4Mob />
            </Grid>
            <Grid style={{ height: '330px'}} className={classes.paper} item xs={12} sm={12}>
              <Equip5Mob />
            </Grid>
            <Grid style={{ height: '240px'}} className={classes.paper} item xs={12} sm={12}>
              <Equip6Mob />
            </Grid>
            <Grid style={{ height: '315px'}} className={classes.paper} item xs={12} sm={12}>
              <Equip7Mob />
            </Grid>
          </Grid>  
  );
};
