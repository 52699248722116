import React, { useContext } from 'react';
import { Container, HeadingsRed, TextRed} from 'components/common';
import { Headings } from 'components/common';
import { RedLine } from 'components/common';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '../../../assets/icons/moreicon.png';
import divider from '../../../assets/illustrations/divider.svg';
import styled from 'styled-components';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0) !important' 
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2) !important',
  },
  divider: {
    marginBottom: '10px'
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '20px'
  },

  extended: {
    transform: 'rotate(0deg)',

    '&:hover': {
   
    transform: 'rotate(45deg)',
    // animationFillMode: 'forwards',
    // animationDelay: '5s',
    // animationName: '$plus',
    // animation: '3s 1',

      },
  },

  // '@keyframes plus': {
  //   from:{
  //     transform: 'rotate(0deg)',
  //   },
  //   to : {
  //     transform: 'rotate(45deg)',
  //   }
  //   } 
 


}));

export const Questions = () => {
  const classes = useStyles();


  return (
    <Wrapper id="FAQ">
      <IntroWrapper as={Container}>
          <Headings> 
            <RedLine />
            У вас остались <HeadingsRed>вопросы</HeadingsRed>?
          </Headings>
      <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content1"
          id="panel1a-header2"
        >
        <div className={classes.content}>
        <span>Для чего нужна <TextRed>система Orpak</TextRed>?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Данная система оптимизирует бизнес-процессы в вашей топливной инфраструктуре. <br />
          Под оптимизацией мы понимаем:<br />
          <br/> 
          - Исключение человеческого фактора (ошибки, махинации) из процессов выдачи топлива, учёта топлива за счет организации полностью безоператорного отпуска.<br />
          <br/> 
          - Значительное ускорение процесса заправки.<br />
          <br/> 
          - Независимая автоматическая отчётность, полностью защищённая от попыток внесения «корректировок» со стороны заинтересованного персонала.<br />
          <br/> 
          - Полная уверенность в том, что всё топливо, выдаваемое из носика пистолета, попадает в бак нужного ТС.<br />
          <br/> 
          - Оптимизация бизнес-процессов приводит к сокращению затрат на ежемесячные закупки топлива на 10-30%.<br />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть" />
    </div>

    <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content3"
          id="panel1a-header3"
        >
        <div className={classes.content}>
        <span><TextRed>Что особенного</TextRed> в системе Orpak?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Система Orpak уникальна тем, что использует специальные RFID-метки для авторизации транспортных средств. Метки ставятся на горловины баков. При заправке они считываются специальным датчиком на топливораздаточном пистолете, что позволяет системе авторизовать машину, вести по ней отчётность, точно понимать какое количество топливо, тип топлива, в какое время было отдано в какую машину. Весь учёт выданного топлива ведётся по RFID-меткам без какого-либо участия водителей и операторов. <br/> 
          <br/> Вы можете установить разные лимиты по выдаче топлива на разные группы машин, разные автопарки, или на каждую машину в отдельности. Система будет отслеживать, сколько топлива выбрала каждая машина из своего лимита, и не выдаст ни литом больше положенного. <br/> 
          <br/> В Orpak доступна двухфакторная система авторизации:Каждому водителю дополнительно выдаётся специальный брелок для подтверждения его работы на машине в определённую смену. Водитель вставляет пистолет в бак, система авторизует ТС. Водитель прикладывает брелок к специальному считывателю, расположенному рядом с ТРК, система подтверждает личность водителя. <br/> 
          <br/> Таким образом контроль ведётся по машине и по водителю. Ответственность каждого водителя становится персональной.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть" />
    </div>

    <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content4"
          id="panel1a-header4"
        >
        <div className={classes.content}>
        <span>Чем Orpak лучше систем автоматизации, использующих <TextRed>Mifare или Em-marine карты</TextRed>?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          В системах, использующих карты, отчётность ведётся по картам, а не по машинам. Водители могут меняться картами, заправлять несколько машин по одной карте. Карточные системы не дают гарантии, что всё топливо, выдаваемое из носика пистолета, попадает в бак. Многие вещи, при использовании данного типа систем, остаются на совести водителя. Использование карт, к сожалению, не исключает возможности махинаций и ошибок. <br/> 
          <br/> В Orpak средством учёта служит сама машина (а точнее метка, установленнаяна ней). Поменяться метками невозможно. В случае попытки несанкционированного съёма метка саморазрушается. Также, авторизуется и водитель. Система Orpak не дает ни водителю, ни оператору никаких возможностей для нецелевых трат топлива, «подгона» отчётов, сговоров и прочего. Система гарантирует, что все бизнес-процессы в области выдачи и учёта топлива будут протекать так, как были задуманы изначально. <br/> 
          <br/> Как правило, АЗС, оборудованные Orpak, работают в полностью безоператорном режиме, поскольку оператор в данном случае не нужен. Все его функции выполняет система, но делает это беспристрастно и неподкупно.Это позволяет сократить расходы на фонд оплаты труда.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть"/>
    </div>

    <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content5"
          id="panel1a-header5"
        >
        <div className={classes.content}>
        <span><TextRed>В каких сферах</TextRed> может быть применима система Orpak?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Orpak будет полезен любым компаниям, имеющим собственный автопарк, закупающим топливо и обладающими собственными точками выдачи (АЗС, топливозаправщики). <br/> 
          <br/>  В основном это:<br/> 
          1.Горнодобывающие компании<br/> 
          2.Городской пассажирский транспорт<br/> 
          3.Агропромышленные компании<br/> 
          4.Морские порты<br/> 
          5.Аэропорты<br/> 
          6.Строительные компании<br/> 
          7.Нефтяные компании
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть"/>
    </div>

    <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content6"
          id="panel1a-header6"
        >
        <div className={classes.content}>
        <span>Как устроена <TextRed>RFID-метка</TextRed>?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          RFID-метка состоит из двух частей – сама метка и кольцо-антенна. Антенна в виде кольца устанавливается на горловину бака ТС. Метка же может быть закреплена где угодно на автомобиле, в защищённом месте. С антенной она соединена по проводу. Возможно крепление метки на самой антенне.
          <br/> <br/> Для крепления метки используются специальные стяжки, нет необходимости сверления или варки на горловине бака. Установка и съём происходят без изменения конструкции горловины. Антенны доступны разных диаметров, какдля легковых-грузовых автомобилей, так и для горловин тепловозных и корабельных баков. <br/> 
          <br/> Метка не обладает никакими элементами питания, она полностью пассивна и не требует обслуживания. Прекрасно работает как в условиях высоких, так и крайне низких температур.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть"/>
    </div>

    <div className={classes.root} >
      <Accordion className={classes.accordion}>
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1a-content7"
          id="panel1a-header7"
        >
        <div className={classes.content}>
        <span>Нужно ли мне привлекать дополнительных подрядчиков, если <TextRed>я хочу установить Orpak</TextRed>?</span>
        <img className={classes.extended} src={ExpandMoreIcon} alt="Раскрыть"/>
        </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          В случае приобретения вами Orpak, наши специалисты  подберут оборудование, произведут установку, пуско-наладку, введение в эксплуатацию и обучение вашего персонала использованию системы. Иными словами, весь проект делается под ключ, с вашей стороны дополнительных работ не потребуется.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img className={classes.divider} src={divider} alt="Раскрыть"/>
    </div>
      </IntroWrapper>          
    </Wrapper>
  );
};
