import React, { useContext } from "react";
import { Container, HeadingsRed } from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import { Wrapper, IntroWrapper, Details } from "./styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import description from "assets/illustrations/description.png";
import ScrollAnimation from "react-animate-on-scroll";
import redhalffull from "assets/illustrations/redhalffull.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    width: "40vw",
    [`@media (max-width: 960px)`]: {
      width: "90vw",
    },
    [`@media (min-width: 1910px)`]: {
      width: "30vw",
    },
  },
  descriptionUp: {
    maxWidth: "30vw",
    position: "relative",
    // opacity:0,/*Элемент полностью прозрачный (невидимый)*/
    // transition: 'left 7s', /*Скорость перехода состояния элемента*/
    // animation: '3s 1',  /* Указываем название анимации, её время и количество повторов*/
    // animationFillMode: 'forwards', /* Чтобы элемент оставался в конечном состоянии анимации */
    // animationDelay: '5s', /* Задержка перед началом */
    // animationName: '$show',
    [`@media (max-width: 960px)`]: {
      display: "none",
    },
  },
  description: {
    [`@media (min-width: 1920px )`]: {
      minWidth: "400px !important",
    },
  },
  descriptionUpWrapper: {
    display: "flex",
    alignItems: "center",
    minWidth: "30vw",
  },
  redCircle: {
    marginLeft: "-100px",

    [`@media (max-width: 1910px )`]: {
      display: "none",
      minWidth: "200px !important",
    },
  },
  leftPart: {
    [`@media (min-width: 1910px )`]: {
      maxWidth: "35vw",
    },
  },
  //  '@keyframes show': {
  //     from:{
  //     opacity:0,
  //     right: '-100%'
  //     },
  //     to : {
  //     opacity:1,
  //     right: '-15%'
  //     }
  //     }
}));

export const AboutCompany = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
        <div className={classes.leftPart}>
          <Headings>
            <RedLine />О нас
          </Headings>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6} xl={6}>
              <div className={classes.paper}>
                Промышленно-производственный холдинг «ЛПГруп» (LPGroup) является
                одним из лидеров в области инжиниринга для сферы
                НефтеПродуктоОбеспечения с широкой географией предлагаемых
                продуктов и услуг, прямым импортёром и производителем с 1997
                года, надежным партнером крупнейших компаний топливного ритейла
                и нефтегазовой отрасли.
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6} xl={6}>
              <div className={classes.paper}>
                Конкурентным преимуществом компании является возможность для
                клиента реализовать проект из одних рук. Мы предоставляем полный
                цикл работ и услуг: консалтинг, проектирование, производство
                продукции и поставку, ввод в эксплуатацию, сервис, внедрение
                технологий и решений любой сложности. Собственная сервисная
                служба предоставляет свою поддержку и выполнение комплексных
                строительно-монтажных работ по всему Казахстану, в самых тяжелых
                для этого условиях, без выходных, 365 дней в году.
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.paper}>
                Более 10 лет LPGroup – официальный дистрибьютор компании
                Gilbarco Veeder-Root, ведущего мирового производителя широкого
                спектра оборудования для АЗС и нефтебаз, которая с 1865 года
                производит инновационное оборудование и разрабатывает новейшие
                технологии. У нас есть решение для ваших нужд. Наши системы и
                инновационные решения разработаны и протестированы для
                бесперебойной работы, обеспечивая минимальную стоимость
                эксплуатации и наилучшую интеграцию.
              </div>

              <ul className={classes.paper}>
                <li>
                  Технологии, которые являются двигателем мирового прогресса.
                </li>
                <li>Оборудование проверенное временем. </li>
                <li>Инновации нацеленные на развитие будущего.</li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <ScrollAnimation
          animateIn="bounceInRight"
          duration={2}
          animateOnce={true}
          className={classes.descriptionUpWrapper}
        >
          <div className={classes.descriptionUp}>
            <img
              width="450"
              src={description}
              className={classes.imageUp}
              alt="Преимущества"
            ></img>
          </div>
          <div className={classes.redCircle}>
            <img
              width="450"
              className={classes.redCircle}
              src={redhalffull}
              alt="Элемент дизайна красный круг"
            ></img>
          </div>
        </ScrollAnimation>
      </IntroWrapper>
    </Wrapper>
  );
};
