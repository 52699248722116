import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, HeadingsRed, NumberRed, Headings2, ButtonMore } from '../../../../common';

import equip1 from '../../../../../assets/illustrations/equip1.png';
import ModalMobile from '../ModalMobile';

const useStyles = makeStyles(theme => ({
  backdrop: {
    '-webkit-transform': 'translate3d(0,0,0)',
    zIndex: 1100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
  },
  cardHide: {
    paddingRight: '10px',
    maxWidth: 'auto',
    height: '220px',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    transition: 'all .7s ease-in-out',

    fontFamily: 'Roboto,Helvetica,sans-serif',
    fontWeight: '400',
    fontSize: '14px',

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      zIndex: 1,
    },
  },
  imageHide: {
    paddingBottom: '10px',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    transform: 'scale(-1, 1)',
  },
  modalWindow: {
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    fontFamily: 'Roboto,Helvetica,sans-serif',
    fontWeight: '400',
    zIndex: 1100,
    '-webkit-transform': 'translate3d(0,0,0)',
    width: '300px',
  },
  modalWindowWrapper: {
    paddintTop: '100px',
    marginLeft: '30px',
    marginRight: '30px',
  },

  cardOneRightImage: {
    zIndex: 0,
    backgroundColor: 'blue',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f3f3f3',
    height: '200px',
    alignItems: 'center',
  },
  cardOneRightImageItem: {
    maxHeight: '120px',
    width: '120px',
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip1.png" }) {
        childImageSharp {
          fixed(width: 75, height: 173) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: '100%' }}>
      <Grid
        container
        style={{
          backgroundColor: '#f3f3f3',
          height: '100%',
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={4} className={classes.cardText}>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source height="173" width="75" srcSet={data.file.childImageSharp.fixed.srcWebp} type="image/webp" />
              <img height="173" width="75" src={data.file.childImageSharp.fixed.src} alt="Компонент 1" />
            </picture>
            {/*                     
                    <img src={equip1} className={classes.imageHide} alt="Компонент 1"/>
                   */}
          </div>
        </Grid>
        <Grid item xs={8} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <NumberRed style={{ fontSize: '25px', paddingTop: '10px' }}>01.</NumberRed>
            <Headings2 style={{ fontSize: '20px' }}>Контроллер ORPAK</Headings2>
            <span>
              Автономная система управления подачей топлива и сбора данных с возможностью установки на открытом воздухе.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <ModalMobile>
          <b>Контроллер</b> – это промышленный компьютер, обрабатывающий данные со всех устройств системы ORPAK. Он
          обеспечивает функционирование всей инфраструктуры, в том числе считывателей, меток, приёмников WGT и
          программного обеспечения FleetHeadOffice.Устройство контролирует выдачу топлива, сбор данных, генерацию
          отчётов и явлется, по сути, сердцем всей системы. <br />
          <br />
          Контроллеры поставляются в <b>различных вариантах исполнения</b>:<br />
          1.<b>ORIC (островное решение)</b> – контроллер устанавливается на островок безопасности. Лучшее решение для
          коммерческих АЗС.
          <br />
          <br />
          2.<b>OrTOP (устанавливается сверху на колонку)</b> – также, идеально подходит для коммерческих АЗС, не
          занимает много места.
          <br />
          <br />
          3.<b>ORCU 3000 (настенное решение)</b> – самое универсальное решение, может использоваться как на коммерческих
          АЗС, так и на корпоративных заправках, в крупных автопарках. Может быть установлен на стену, в не зоны
          досягаемости водителей.
          <br />
          <br />
          4.<b>ORTC (исполнение для АТЗ)</b> – компактный контроллер, предназначенный для установки в АТЗ.
          Устанавливается в специальный виброзащищённый корпус
        </ModalMobile>
      </Backdrop>
    </div>
  );
};
