import React, { useContext } from 'react';
import { Container, HeadingsRed} from 'components/common';
import { Wrapper, IntroWrapper, Details } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../../../assets/icons/closeicon.png';

const useStyles = makeStyles((theme) => ({  
  modalWindowWrapper:{
    marginLeft: '30px',
    marginRight: '30px',
    marginTop: '100px',
    maxHeight: '600px',
    overflowY: 'scroll'
  },
  modalWindow: {
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    paddingTop: '10px',
    fontFamily: 'Roboto,Helvetica,sans-serif',
    fontWeight: '400',
    zIndex: 1100,
    '-webkit-transform':'translate3d(0,0,0)',
  },

  closeButton: {
    display:'flex',
    width: '100%',
    marginLeft: 'auto',
    paddingBottom: 10,
  },
  extended: {
    marginLeft: 'auto',
    '&:hover': {
     transform: 'scale(1.2)',
    },

  }
}));

export default ({children}) => {
  const classes = useStyles();

  return (
    <Wrapper>
       <div className={classes.modalWindowWrapper} >
       <div className={classes.modalWindow}>
       <div className={classes.closeButton}><img className={classes.extended} src={CloseIcon} alt="Кнопка закрыть"/></div>
       {children}
        </div>
        </div> 
    </Wrapper>
  );
};
