import React, { useContext } from "react";
import { Container, HeadingsRed } from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import { Wrapper, IntroWrapper, Details } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import client5 from "assets/illustrations/client5.png";
import client1 from "assets/illustrations/client1.png";
import client2 from "assets/illustrations/client2.png";
import client3 from "assets/illustrations/client3.png";
import client4 from "assets/illustrations/client4.svg";
import client6 from "assets/illustrations/client6.jpeg";
import client7 from "assets/illustrations/client7.png";
import client8 from "assets/illustrations/client8.png";
import client9 from "assets/illustrations/client9.png";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  clients: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    columnGap: 40,
    rowGap: 10,

    [`@media (max-width: 1025px)`]: {
      width: "100vw",
      justifyContent: "space-around",
      columnGap: 10,
      rowGap: 5,
    },
  },
  clientsImage: {
    opacity: "40%",
    maxWidth: "250px",
    maxHeight: "80px",
    mixBlendMode: "luminosity",
    "&:last-child": {
      mixBlendMode: "darken",
    },
    marginBottom: "0.78vw",
    [`@media (max-width: 1025px)`]: {
      maxWidth: "150px",
      marginBottom: "20px",
      height: "50px",
    },
  },
}));

export const Clients = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
        <Headings>
          <RedLine />С нами работают
        </Headings>{" "}
        <br />
        <div className={classes.clients}>
          <img
            className={classes.clientsImage}
            src={client1}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client2}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client3}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client4}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client5}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client6}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client7}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client8}
            alt="Клиент"
          ></img>
          <img
            className={classes.clientsImage}
            src={client9}
            alt="Клиент"
          ></img>
        </div>
      </IntroWrapper>
    </Wrapper>
  );
};
