import React from "react";
import { Wrapper, Details, Thumbnail } from "./styles";
import { Container, HeadingsRed } from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import ContactForm from "./ContactForm";
import FormContact from "./FormContact";
import DownloadForm from "../DownloadForm";
import ModalWindow from "../ModalWindow";

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details>
      <Headings>
        <RedLine />
        <HeadingsRed>Свяжитесь</HeadingsRed> с нами
      </Headings>

      {/* <ContactForm /> */}
      <FormContact />
    </Details>
  </Wrapper>
);
