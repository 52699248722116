import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
  ButtonMore,
  RedLine,
} from "../../../common";
import equip5_modal from "../../../../assets/illustrations/equip5_modal.png";

import equip5 from "../../../../assets/illustrations/equip5.png";
import CloseIcon from "../../../../assets/icons/closeicon.png";
import Modal from "../Modal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  cardText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardImage: {
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    height: "300px",
    display: "flex",
    justifyContent: "center",
  },
  cardHide: {
    maxWidth: "400px",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: 0,
    transition: "all .7s ease-in-out",

    fontFamily: "Roboto, Helvetica, sans-serif",
    fontWeight: "300",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
    alignItems: "left",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      zIndex: 1,
    },
  },
  imageHide: {
    width: "100%",
    height: "70%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    transform: "scaleY(1)",
    transition: " all .7s ease-in-out",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      opacity: 0,
      zIndex: -10,
    },
  },
  modalWindow: {
    width: "50vw",
    height: "fit-content",
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "30px",
    fontFamily: "Roboto",
    fontWeight: "400",
    zIndex: 1000,
    position: "absolute",
  },
  modalWindowWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddintTop: "100px",
    position: "relative",
  },

  cardOneRightImage: {
    width: "100%",
    transition: " all .7s ease-in-out",
    transform: "scaleY(1)",
    backgroundColor: "blue",

    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f3f3f3",
    height: "100%",
    alignItems: "center",
    "&:hover": {
      zIndex: -10,
    },
  },
  cardOneRightImageItem: {
    maxHeight: "400px",
    width: "100%",
  },
  closeButton: {
    display: "flex",
    width: "100%",
    marginLeft: "auto",
    paddingBottom: 20,
  },
  extended: {
    marginLeft: "auto",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  modalImage: {
    paddingTop: 50,
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip5.png" }) {
        childImageSharp {
          fixed(width: 215, height: 220) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        style={{
          backgroundColor: "#f3f3f3",
          padding: "10px 0 10px 10px",
          height: "100%",
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={6} className={classes.cardText}>
          <NumberRed>05.</NumberRed>
          <Headings2>RFID-метка + Антенна</Headings2>
        </Grid>
        <Grid item xs={6} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <span>
              Метка проводом соединена с кольцом-антенной, закрепляемой на
              горловине топливного бака в защитном месте автомобиля. При попытке
              демонтажа будет разрушена и заправка не состоится.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                height="220"
                width="215"
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
              />
              <img
                height="220"
                width="215"
                src={data.file.childImageSharp.fixed.src}
                alt="Компонент 2"
              />
            </picture>
            {/* <img src={equip5} className={classes.imageHide} alt="Компонент 5"/> */}
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Modal>
          <RedLine />
          <Headings2 style={{ fontSize: "34px" }}>
            RFID-метка + Антенна
          </Headings2>
          <span>
            Метка проводом соединена с кольцом-антенной, закрепляемой на
            горловине топливного бака в защитном месте автомобиля. При попытке
            демонтажа будет разрушена и заправка не состоится.
          </span>
          {/* <img
            src={equip5_modal}
            alt="equip_modal"
            className={classes.modalImage}
          ></img> */}
          <br />
          <br />
          <b>RFID Устройство</b> для транспортных средств:
          <br />
          <br />
          • RFID-устройство, устанавливается на горловину топливного бака <br />
          • Пассивная RFID метка сантенной <br />
          • Передача данных по защищенным каналам <br />
          • Решение для легковых и грузовых ТС <br />
          • Разнообразие антенн для различных горловин бензобака ТС <br />
          • Механизм защиты от демонтажа <br />
          • Простота установки, подходит для любого автомобиля <br />
        </Modal>
      </Backdrop>
    </div>
  );
};
