import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, HeadingsRed, Headings, RedLine } from 'components/common';

import { makeStyles } from '@material-ui/core/styles';
import phone from 'assets/icons/phone.png';
import whatsup from 'assets/icons/whatsup.png';
import telegram from 'assets/icons/telegram.png';
import email from 'assets/icons/email.png';
import Grid from '@material-ui/core/Grid';
import { Wrapper, IntroWrapper, Details } from './styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  manager: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '40vw',
    margin: '20px 0',
    color: '#C51B10',
    fontSize: '20px',
    fontWeight: '600',
    textDecoration: 'none',

    [`@media (max-width: 960px)`]: {
      width: '90vw',
      paddingLeft: '1rem',
    },
  },
  socials: {
    paddingRight: '20px',
    [`@media (max-width: 960px)`]: {
      paddingRight: '10px',
      maxHeight: '24px',
    },
  },
  a: {
    color: '#C51B10',
  },
  managerName: {
    [`@media (max-width: 960px)`]: {
      paddingLeft: '1rem',
      fontSize: '28px !important',
    },
  },
}));

export const Phones = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
        <Headings>
          <RedLine />
          Свяжитесь с нами
        </Headings>
        <Grid container>
          <Grid item xs={12} sm={6} style={{ paddingBottom: '30px' }}>
            {/* <h2 className={classes.managerName}>Владислав Шамин</h2>
              <div className={classes.manager}>
                <img className={classes.socials} src={phone} alt="Телефон"></img>
                <a href="tel:89811503649" style={{color: '#C51B10',}}>+79811503649</a>
              </div> */}
            <div className={classes.manager}>
              <img src={email} className={classes.socials} alt="Социальные сети"></img>
              <a href="mailto:sales-cis@gilbarco.com" style={{ color: '#C51B10' }}>
                sales-cis@gilbarco.com{' '}
              </a>
            </div>
            {/* <div className={classes.manager}>
                <a href="https://wa.me/79811503649"><img className={classes.socials} src={whatsup} alt="Вотс ап"></img></a>
                <a href="https://t.me/Vlad_Shamin"><img className={classes.socials} src={telegram} alt="Телеграм"></img></a>
              </div> */}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <h2 className={classes.managerName}>Петр Зайцев</h2>
              <div className={classes.manager}>
                <img className={classes.socials} src={phone} alt="Телефон"></img>
                <a href="tel:89214091229" style={{color: '#C51B10',}}>+79214091229</a>
              </div>
              <div className={classes.manager}>
                <img src={email} className={classes.socials} alt="Социальные сети"></img>
                <a href="mailto:petr.zaitsev@gilbarco.com" style={{color: '#C51B10',}}>petr.zaitsev@gilbarco.com</a>
              </div>
              <div className={classes.manager}>
              <a href="https://wa.me/79214091229"><img className={classes.socials} src={whatsup} alt="Вотс ап"></img></a> 
              </div>
            </Grid> */}
        </Grid>

        <div className={classes.clients}></div>
      </IntroWrapper>
    </Wrapper>
  );
};
