import styled from "styled-components";
import redhalf from "assets/illustrations/redhalf.png";
import redhalffull from "assets/illustrations/redhalffull.png";

export const Wrapper = styled.div`
  width: calc(100% + 0.78vw);
  padding-top: 5rem;
  padding-bottom: 8rem;
  background-image: url(${redhalf});
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  hight: 800px;
  @media (max-width: 960px) {
    background: none;
    padding-top: 4rem;
  }
  @media (min-width: 1910px) {
    background: none;
    background-position: right center;
  }
`;

export const IntroWrapper = styled.div`
  // padding: 4rem 0;
  display: flex;
  align-items: center;
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 100px;
    margin-bottom: -20px;
    font-weight: 800;
    color: ${({ theme }) => (theme === "light" ? "#212121" : "#fff")};
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 40px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => (theme === "light" ? "#222222" : "#ffffff")};
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    margin-left: 30px;
    padding-top: 15px;
    max-width: 500px;
    max-height: 170px;
  }
`;
