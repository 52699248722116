import React, { useContext } from "react";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
  Headings,
  RedLine,
} from "../../../common";
import { ButtonMore } from "../../../common";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "../Modal";
import { useStaticQuery, graphql } from "gatsby";
import equip1_modal from "../../../../assets/illustrations/equip1_modal.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  cardOne: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f3f3f3",
    padding: "10px 0 10px 10px",
    marginRight: "15px",
    clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
  },
  cardOneLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "150px",
  },
  cardOneRight: {
    position: "relative",
    height: "585px",
    width: "170px",
    display: "flex",
    justifyContent: "center",
  },

  cardOneRightText: {
    padding: "10px",
    height: "100%",
    backgroundColor: "#f3f3f3",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
    fontFamily: "Roboto, Helvetica, sans-serif",
    fontWeight: 300,
    "&:hover": {
      zIndex: 10,
    },
  },

  cardOneRightImage: {
    width: "180px",
    maxHeight: "auto",
    transition: " all .7s ease-in-out",
    transform: "scaleY(1)",
    backgroundColor: "#f3f3f3",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      zIndex: -10,
    },
    [`@media (max-width: 1025px)`]: {
      width: "100%",
    },
  },
  cardOneRightImageItem: {
    maxHeight: "580px",
    width: "100%",
  },
  modalImage: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip1.png" }) {
        childImageSharp {
          fixed(height: 370, width: 150) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.cardOne}
      >
        <Grid item xs={6} className={classes.cardOneLeft}>
          <NumberRed>01.</NumberRed>
          <Headings2>Контроллер ORPAK</Headings2>
        </Grid>
        <Grid item xs={6} className={classes.cardOneRight}>
          <div className={classes.cardOneRightText}>
            <span>
              Считыватель RFID-меток, устанавливается на каждый заправочный
              пистолет колонки раздачи топлива.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>

          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
                height="370"
                width="150"
              />
              <img
                src={data.file.childImageSharp.fixed.src}
                alt="Компонент 1"
                height="370"
                width="150"
              />
            </picture>

            {/* <img src={equip1}className={classes.cardOneRightImageItem} alt="Компонент 1"/> */}
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Modal>
          <RedLine />
          <Headings2 style={{ fontSize: "34px" }}>Контроллер ORPAK</Headings2>
          Это промышленный компьютер, обрабатывающий данные со всех устройств
          системы ORPAK. Он обеспечивает функционирование всей инфраструктуры, в
          том числе считывателей, меток, приёмников WGT и программного
          обеспечения FleetHeadOffice.Устройство контролирует выдачу топлива,
          сбор данных, генерацию отчётов и явлется, по сути, сердцем всей
          системы. <br />
          {/* <img
            src={equip1_modal}
            alt="equip_modal"
            className={classes.modalImage}
          ></img> */}
          <br />
          Контроллеры поставляются в <b>различных вариантах исполнения</b>:
          <br />
          <br />
          1.<b>ORIC (островное решение)</b> – контроллер устанавливается на
          островок безопасности. Лучшее решение для коммерческих АЗС.
          <br />
          <br />
          2.<b>OrTOP (устанавливается сверху на колонку)</b> – также, идеально
          подходит для коммерческих АЗС, не занимает много места.
          <br />
          <br />
          3.<b>ORCU 3000 (настенное решение)</b> – самое универсальное решение,
          может использоваться как на коммерческих АЗС, так и на корпоративных
          заправках, в крупных автопарках. Может быть установлен на стену, в не
          зоны досягаемости водителей.
          <br />
          <br />
          4.<b>OrTC</b> – исполнение, предназначенное для установки на АТЗ.
          Контроллер размещён в виброзащищённом герметичном металлическом кейсе.
          Может распологаться вне кабины топливозаправщика.
        </Modal>
      </Backdrop>
    </div>
  );
};
