import React, { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Container, TextGreen, TextBlue, HeadingsRed } from "components/common";
import { Headings, Headings2 } from "components/common";
import { RedLine } from "components/common";
import { Wrapper, IntroWrapper, Details, Thumbnail } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import adv1 from "assets/illustrations/advantage1.png";
import adv2 from "assets/illustrations/advantage2.png";
import adv3 from "assets/illustrations/advantage3.png";
import adv4 from "assets/illustrations/advantage4.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    [`@media (max-width: 960px)`]: {
      padding: theme.spacing(1),
    },
  },
  advImage: {
    [`@media (max-width: 500px)`]: {
      width: "20vw",
    },
    [`@media (max-width: 993px) and (min-width: 501px)`]: {
      width: "10vw",
    },
  },
}));

export const Advantages = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <IntroWrapper as={Container}>
        <Headings>
          <RedLine />
          Наши преимущества
        </Headings>

        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid item sm={7} md={4} lg={4}>
            <div className={classes.paper}>
              <img
                width="150"
                height="auto"
                className={classes.advImage}
                src={adv1}
                alt="Уменьшение потери топлива"
              />
              <Headings2>Сокращение потерь топлива</Headings2>
              {/* <TextBlue></TextBlue> */}
              Полный контроль и оптимизация за счет внедрения ORPAK. Гарантия
              100% попадания выданного топлива в бак конкретного ТС.
            </div>
          </Grid>

          <Grid item sm={7} md={4} lg={4}>
            <div className={classes.paper}>
              <img
                width="150"
                height="auto"
                className={classes.advImage}
                src={adv2}
                alt="Комплексное управление"
              />
              <Headings2>Комплексная оптимизация</Headings2>
              {/* <TextGreen></TextGreen> */}
              Автоматизированный процесс заправки и формирования отчётности без
              оператора.
            </div>
          </Grid>

          {/* <Grid item sm={6} md={6} lg={3}>
            <div className={classes.paper}>
              <img
                width="150"
                height="auto"
                className={classes.advImage}
                src={adv3}
                alt="Снижение отчислений на ФОТ"
              />
              <Headings2>Снижение отчислений на ФОТ</Headings2>
              <TextBlue>
                Экономия на содержании, обучении, повышении квалификации
              </TextBlue>{" "}
              персонала, работающего на АЗС. Заправки, оборудованные ORPAK,
              полностью автономны и безоператорны.
            </div>
          </Grid> */}

          <Grid item sm={7} md={4} lg={4}>
            <div className={classes.paper}>
              <img
                width="150"
                height="auto"
                className={classes.advImage}
                src={adv4}
                alt="Уменьшение потери топлива"
              />
              <Headings2>Исключение участия людей</Headings2>
              {/* <TextGreen></TextGreen> */}
              Использование ORPAK делает бессмысленными любые попытки махинаций
              и исключает возможность ошибок.
            </div>
          </Grid>
        </Grid>
      </IntroWrapper>
    </Wrapper>
  );
};
