import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import equip2 from '../../../../../assets/illustrations/equip2.png';
import { ButtonMore, Container, HeadingsRed, NumberRed, Headings2 } from '../../../../common';
import ModalMobile from '../ModalMobile';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
  },
  cardHide: {
    paddingRight: '10px',
    maxWidth: 'auto',
    height: '220px',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    transition: 'all .7s ease-in-out',

    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontWeight: '400',
    fontSize: '14px',

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      zIndex: 1,
    },
  },
  imageHide: {
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    transform: 'scale(-1, 1)',
    [`@media (max-width: 450px)`]: {
      height: '80%',
    },
  },
  modalWindow: {
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    zIndex: 1000,
    width: '300px',
  },
  modalWindowWrapper: {
    // wigth: '100%',
    paddintTop: '100px',
    marginLeft: '30px',
    marginRight: '30px',
  },

  cardOneRightImage: {
    zIndex: 0,
    paddingRight: '5px',
    // transition:' all .7s ease-in-out',
    // transform: 'scaleY(1)',
    backgroundColor: 'blue',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'left',
    backgroundColor: '#f3f3f3',
    height: '200px',
    alignItems: 'center',
    // '&:hover': {
    //   zIndex:-10
    // }
  },
  cardOneRightImageItem: {
    maxHeight: '120px',
    width: '120px',
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip2.png" }) {
        childImageSharp {
          fixed(width: 114, height: 150) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: '100%' }}>
       <Grid
      container
      style={{
        backgroundColor: '#f3f3f3',
        height: '100%',
        clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
      }}
    >
      <Grid item xs={4} className={classes.cardText}>
        <div className={classes.cardOneRightImage}>
          <picture>
            <source height="150" width="115" className={classes.imageHide} srcSet={data.file.childImageSharp.fixed.srcWebp} type="image/webp" />
            <img height="150" width="115" className={classes.imageHide} src={data.file.childImageSharp.fixed.src} alt="Компонент 2" />
          </picture>
          {/* <img src={equip2} className={classes.imageHide} alt="Компонент 2"/> */}
        </div>
      </Grid>
      <Grid item xs={8} className={classes.cardImage}>
        <div className={classes.cardHide}>
          <NumberRed style={{ fontSize: '25px', paddingTop: '10px' }}>02.</NumberRed>
          <Headings2 style={{ fontSize: '20px' }}>Считыватель RFID-меток</Headings2>
          <span>
            Считыватель RFID-меток, устанавливается на каждый заправочный пистолет топливораздаточной колонки.
          </span>
          <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
        </div>
      </Grid>
    </Grid>
    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <ModalMobile>
          <b>RFID считывающее устройство</b> состоит из: <br />
          • Автономный компактный транспондер <br />
          • Срок службы батареи ~3 года <br />
          • Светодиодная индикация <br />
          • Чрезвычайно прочный и надежный корпус <br />
          • Эффективная защита от демонтажа
          <br />
          • Подходит для всех типов сопел, держателей и входных отверстий в баки транспортных средств <br />
          • Инновационный механизм крепления
          <br /> <br />
          Качество подтвеждено сертификатами: ATEX, FCC, UL и российским сертификатом, подтверждающим применение на
          взрывоопасных зонах.
          <br />
        </ModalMobile>
      </Backdrop>
    </div>
   
  );
};
