import React, { useContext } from 'react';
import { grphql, useStaticQuery } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header } from 'components/theme';
import { Container, Button, RedLine, HeadingsRed, TextRed } from 'components/common';
import redcircle from 'assets/icons/redcircle.svg';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import orpak from '../../common/Images/orpak.png';
import { Wrapper, IntroWrapper, Details, Thumbnail, IntroMobile, IntroDesktop } from './styles';
import { Headings } from '../../common';

const useStyles = makeStyles(theme => ({
  redCircleAnimate: {
    transform: 'scale(.85)',
    animation: '3s ease-in-out infinite',
    animationName: '$pulse',
  },
  '@keyframes pulse': {
    from: {
      transform: 'scale(.75)',
    },
    '50%': {
      transform: ' scale(1.1)',
    },
    to: {
      transform: 'scale(.75)',
    },
  },
  buttonContainer:{
    width: '100%',
    '@media (max-width: 960px)': {
      width: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@media (max-width: 720px)': {
      marginTop: '18vh',
    },
  },
  banner: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '@media (min-width: 1920px)': { height: 1067 },
    '@media (max-width: 960px)': {
      marginLeft: 0,
      height: '100vh',
    },
  },
  banner__image: {
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    '@media (min-width: 1920px)': { width: 1920, height: 1067 },
    '@media (max-width: 960px)': {
      height: '100vh',
    },
  },
  shader: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '@media (min-width: 1920px)': { width: 1920, height: 1067 },
    '@media (max-width: 960px)': {
      height: '100vh',
    },
  },
  insideInfo: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    padding: '10vw 0vw 3.9vw 0vw',
    '@media (min-width: 1920px)': {
      padding: '120px 0px 75px 0px',
    },
    '@media (max-width: 960px)': {
      alignItems: 'center',
      padding: '7rem 1rem 14rem 1rem',
    },
    '@media (max-width: 720px)': {
      justifyContent: 'unset',
    },
  },
  Heading: {
    width: '100%',
    zIndex: '4',
    margin: '2vw 0vw 0vw 0vw',
    marginBottom: '0px !important',
    color: '#ffff',
    fontSize: '4.06vw !important',
    lineHeight: '5vw !important',
    fontWeight: '700 !important',
    '@media (min-width: 1920px)': {
      margin: '2vw 0vw 0vw 0vw',
      fontSize: '78px !important',
      lineHeight: '96px !important',
    },
    '@media (max-width: 960px)': {
      fontSize: '6vw !important',
      lineHeight: '8vw !important',
      fontWeight: '700 !important',
    },
    '@media (max-width: 720px)': {
      fontSize: '6vw !important',
      lineHeight: '8vw !important',
      fontWeight: '700 !important',
    },
  },
  orpakContainer: {
    display: 'flex',
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  orpakContent: {
    color: '#ffff',
    fontSize: '1.7vw !important',
    '@media (min-width: 1920px)': {
      fontSize: '33px !important',
    },
  },
  threeColor: {
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    height: '1vw',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 1920px)': {
      width: 1920,
      height: 20,
    },
  },
}));

export const Intro = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1740)
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details>
          <div className={classes.banner}>
            <GatsbyImage
              image={data.file.childImageSharp.gatsbyImageData}
              alt="banner"
              width={1740}
              height={978}
              className={classes.banner__image}
              imgStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className={classes.shader} />
            <div className={classes.insideInfo}>
              <div style={{ width: '100%' }}>
                <h1 className={classes.Heading}>
                  УПРАВЛЕНИЕ ТОПЛИВНОЙ <br /> ИНФРАСТРУКТУРОЙ И <br /> АВТОПАРКАМИ
                </h1>
              </div>

              <div className={classes.buttonContainer}>
                <Button as={AnchorLink} href="#calculator">
                  <p>Рассчитать экономию</p>
                  <img className={classes.redCircleAnimate} src={redcircle} alt="Элемент дизайна" />
                </Button>
              </div>

              <div className={classes.orpakContainer}>
                <p className={classes.orpakContent}>
                  <b>ORPAK</b> — это программно-аппаратный комплекс для полного контроля <br /> над автопарками и
                  топливной инфраструктурой
                </p>
              </div>
            </div>

            <div className={classes.threeColor}>
              <div style={{ width: '100%', display: 'flex', height: '100%' }}>
                <div
                  style={{
                    backgroundColor: '#d51e47',
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div
                  style={{
                    backgroundColor: '#085796',
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div
                  style={{
                    backgroundColor: '#689246',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          </div>

          {/* <IntroDesktop>
            <h1>
              ЭКОНОМЬ ДО <HeadingsRed>15%</HeadingsRed>
            </h1>
            <h1>
                <Thumbnail>
                  ТОПЛИВА С
                  <img width="500" height="170" src={orpak} alt="ORPAK" />
                </Thumbnail>
            </h1>
          </IntroDesktop>

          <IntroMobile>
            <h1>
              ЭКОНОМЬ ДО <HeadingsRed>15%</HeadingsRed>ТОПЛИВА С
            </h1>
            <Thumbnail>
              <img src={orpak} alt="ORPAK" />
            </Thumbnail>
          </IntroMobile> */}
        </Details>
      </IntroWrapper>
    </Wrapper>
  );
};
