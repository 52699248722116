import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 8rem;
`;

export const IntroWrapper = styled.div`
  // padding: 4rem 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const TitleDownload = styled.p`
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  margin-top: 5.58vw;
  margin-bottom: 1.32vw;
  font-size: 2.81vw;
  @media (min-width: 1280px) {
    margin-top: 75px;
    margin-bottom: 17px;
    font-size: 36px;
  }
  @media (max-width: 500px) {
    margin-top: 7.81vw;
    margin-bottom: 1.77vw;
    font-size: 3.75vw;
  }
`;

export const ButtonRed = styled.button`
  margin: auto;
  outline: none;
  border: none;
  background: #c51b10;
  cursor: pointer;

  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  text-align: center;
  color: #ffffff !important;

  padding: 0.7rem 2.5rem;
  border-radius: 44px;
  font-size: 16px;

  @media (min-width: 960px) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 100px;
    margin-bottom: -20px;
    font-weight: 800;
    color: ${({ theme }) => (theme === "light" ? "#212121" : "#fff")};
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 45px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => (theme === "light" ? "#222222" : "#ffffff")};
    font-family: "Montserrat";

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }

    @media (max-width: 680px) {
      font-size: 16px;
    }
  }
`;

export const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    margin-left: 30px;
    padding-top: 15px;
    max-width: 500px;
    max-height: 170px;
  }
`;

export const ImageWrapper = styled.div`
  @media (max-width: 500px) {
    img {
      min-width: 1000px;
    }
    width: 100vw;
    overflow-x: scroll;
  }
`;

export const Helper = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    max-width: fit-content;
    margin-left: 1rem;
    font-weight: 700;

    img {
      margin-left: 20px;
      min-width: 40px;
      padding-top: 5px;
    }
  }
`;
