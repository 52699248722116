import React, { useContext } from "react";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
} from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import { Wrapper, IntroWrapper } from "./styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Equip1 from "./Equip1";
import Equip2 from "./Equip2";
import Equip4 from "./Equip4";
import Equip3 from "./Equip3";
import Equip5 from "./Equip5";
import Equip6 from "./Equip6";
import Equip7 from "./Equip7";
import EquipmentMobile from "./EquipmentMobile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    fontFamily: "Montserrat",
  },
  mobilever: {
    display: "none",
    [`@media (max-width: 1030px)`]: {
      display: "inline-block",
    },
  },
  desctopver: {
    [`@media (max-width: 1030px)`]: {
      display: "none",
    },
  },
  column: {
    width: "32%",
    marginRight: "2%",

    "&:last-child": {
      marginRight: 0,
    },
  },
  firstBottomColumn: {
    width: "49%",
    marginRight: "2%",
  },
  secondBottomColumn: {
    width: "49%",
  },
}));

export const Equipment = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Wrapper id="equipment">
      <IntroWrapper as={Container}>
        <Headings>
          <RedLine />
          Что входит в <HeadingsRed>комплект</HeadingsRed>?
        </Headings>
        <div className={classes.mobilever}>
          <EquipmentMobile />
        </div>

        <div className={classes.desctopver}>
          <Grid container>
            <Grid className={classes.column} item>
              <Equip1 />
            </Grid>

            <Grid className={classes.column} item>
              <div>
                <Equip2 />
              </div>
              <div style={{ height: "340px", marginTop: "15px" }}>
                <Equip4 />
              </div>
            </Grid>

            <Grid className={classes.column} item>
              <div>
                <Equip3 />
              </div>
              <div style={{ height: "340px", marginTop: "15px" }}>
                <Equip5 />
              </div>
            </Grid>
          </Grid>

          <Grid style={{ marginTop: "15px" }} container>
            <Grid className={classes.firstBottomColumn} item>
              <Equip6 />
            </Grid>
            <Grid
              className={classes.secondBottomColumn}
              item
              // style={{ width: '620px', marginTop: '15px', marginLeft: '15px',[`@media (max-width: 1360px)`]: {
              // width: '600px'}}}
            >
              <Equip7 />
            </Grid>
          </Grid>
        </div>
      </IntroWrapper>
    </Wrapper>
  );
};
