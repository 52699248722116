import React, { useContext } from "react";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
  RedLine,
} from "../../../common";
import { ButtonMore } from "../../../common";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import equip4 from "../../../../assets/illustrations/ORPAY1000.png";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "../../../../assets/icons/closeicon.png";
import Modal from "../Modal";
import { useStaticQuery, graphql } from "gatsby";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
    paddingTop: 70,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: "450px",
    height: "640px",
    marginRight: "15px",
  },
  cardText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardImage: {
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    height: "300px",
    display: "flex",
    justifyContent: "center",
  },
  cardHide: {
    maxWidth: "400px",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: 0,
    transition: "all .7s ease-in-out",

    fontFamily: "Roboto, Helvetica, sans-serif",
    fontWeight: "300",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
    alignItems: "left",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      zIndex: 1,
    },
  },
  imageHide: {
    width: "100%",
    height: "70%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    transform: "scaleY(1)",
    transition: " all .7s ease-in-out",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      // display: 'none',
      opacity: 0,
      zIndex: -10,
    },
  },
  modalWindow: {
    width: "50vw",
    height: "fit-content",
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "30px",
    fontFamily: "Roboto",
    fontWeight: "400",
    zIndex: 1000,
    position: "absolute",
  },
  modalWindowWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddintTop: "100px",
    position: "relative",
  },
  cardOneRightImage: {
    // position: 'absolute',
    width: "100%",
    // maxHeight: '400px',
    transition: " all .7s ease-in-out",
    transform: "scaleY(1)",
    backgroundColor: "blue",

    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f3f3f3",
    height: "100%",
    alignItems: "center",
    "&:hover": {
      // opacity: 0,
      zIndex: -10,
    },
  },
  cardOneRightImageItem: {
    maxHeight: "400px",
    width: "100%",
  },
  closeButton: {
    display: "flex",
    width: "100%",
    marginLeft: "auto",
    paddingBottom: 20,
  },
  extended: {
    marginLeft: "auto",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "ORPAY1000.png" }) {
        childImageSharp {
          fixed(height: 170, width: 215) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        style={{
          backgroundColor: "#f3f3f3",
          padding: "10px 0 10px 10px",
          height: "100%",
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={6} className={classes.cardText}>
          <NumberRed>04.</NumberRed>
          <Headings2>OrPAY1000</Headings2>
        </Grid>
        <Grid item xs={6} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <span>
              Новый внешний платежный терминал Orpak, устанавливаемый
              непосредственно на ТРК или как отдельный терминал и
              предназначенный для контролируемых и неконтролируемых операций.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                height="170"
                width="215"
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
              />
              <img
                height="170"
                width="215"
                src={data.file.childImageSharp.fixed.src}
                alt="Компонент 2"
              />
            </picture>
            {/* <img src={equip4} className={classes.imageHide} alt="Компонент 4" /> */}
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Modal>
          <RedLine />
          <Headings2 style={{ fontSize: "34px" }}>OrPAY1000</Headings2>
          <span>
            Уникальные возможности терминала прекрасно подходят как для
            розничных АЗС, так и заправочных станций автопарков. Это безупречное
            решение для оплаты при помощи топливных карт, проведения
            промо-акций, управления счетами и схемами лояльности, управления
            сопутствующими услугами и многого другого.
          </span>
          <br />
          <br />
          <span>
            Кроме того, OrPAY1000 имеет встроенный «интерфейс насоса», который
            используется для управления ТРК, а также напрямую взаимодействует с
            контроллером АЗС Orpak по локальной сети, таким образом, нет
            необходимости в установке специальных дополнительных устройств.
          </span>
          <br />
          <br />
          <span>
            Терминал OrPAY1000 имеет достаточно компактные размеры, позволяющие
            устанавливать его как отдельный терминал или в насосе ТРК. При этом
            терминал имеет эффективный и расширенный пользовательский интерфейс:
            4,3-дюймовый мультимедийный цветной ЖК-дисплей, 4 экранные кнопки и
            полная антивандальная буквенно-цифровая 40-клавишная клавиатура.
            Кроме того, благодаря встроенному беспроводному шлюзу WGT этот
            новаторский терминал может быть частью решения ForeFuel компании
            Orpak.
          </span>
          <br />
          <br />
          <span>
            <strong>Возможности</strong>
            <br />
            <li>
              Долговечный высокорентабельный терминал для авторизации
              заправочных операций и покупки топлива
            </li>
            <li>
              Компактный и простой в использовании терминал с привлекательным
              цветным экраном, полной буквенно-цифровой клавиатурой и экранными
              кнопками
            </li>
            <li>
              Терминал поддерживает все способы оплаты, не относящимися к
              стандарту EMV
            </li>
            <li>
              Демонстрация рекламных предложений на экране для привлечения новых
              клиентов, что способствует росту продаж в магазине и на АЗС
            </li>
            <li>
              Быстрое развертывание и простая интеграция с любым оборудованием
              АЗС по локальной сети или RS-485
            </li>
            <li>
              Простое техническое обслуживание с удаленным доступом для загрузки
              обновлений программного обеспечения, диагностики и настройки
            </li>
            <li>
              Дополнительный маршрутизатор WGT (беспроводной шлюз) для
              автоматической идентификации транспортного средства в составе
              решения ForeFuel
            </li>
            <li>
              Дополнительный модуль взаимодействия с насосом для управления ТРК,
              поддерживающий различные марки и виды ТРК
            </li>
            <li>
              Полная интеграция с решением ForeSite компании Orpak, а также
              сторонними POS-системами
            </li>
            <strong>Варианты оплаты</strong>
            <br />
            - Топливные карты и карты автопарка с магнитной лентой
            <br />
            - Бесконтактные карты MIFARE®️ и метки 125K
            <br />
            - Встроенный считыватель штрих-кодов для флаеров и купонов
            <br />
          </span>
          {/* <img src={equip4_modal} alt="equip_modal" className={classes.modalImage}></img> */}
          {/* <b>Беспроводной шлюз</b> обеспечивает: <br />
          • Организацию безопасной беспроводной сети станции типа «mesh». <br />
          • Подключение компонентов ForeFuel к автоматизации станции.
          <br />
          • Использование 802.15.4 (Zigbee) на частоте 2,4ГГц.
          <br />
          • Высокую доступность, надежность и отказоустойчивость.
          <br />• Дистанционное обслуживание и обновление прошивок для всех ForeFuel компонентов. */}
        </Modal>
      </Backdrop>
    </div>
  );
};
