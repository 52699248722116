import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, HeadingsRed, NumberRed, Headings2, ButtonMore } from '../../../../common';

import equip3 from '../../../../../assets/illustrations/equip3.png';
import ModalMobile from '../ModalMobile';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
  },
  cardHide: {
    paddingRight: '10px',
    maxWidth: 'auto',
    height: '220px',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    transition: 'all .7s ease-in-out',

    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontWeight: '400',
    fontSize: '14px',

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      zIndex: 1,
    },
  },
  imageHide: {
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    transform: 'scale(-1, 1)',
  },
  modalWindow: {
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    zIndex: 1000,
    width: '300px',
  },
  modalWindowWrapper: {
    // wigth: '100%',
    paddintTop: '100px',
    marginLeft: '30px',
    marginRight: '30px',
  },

  cardOneRightImage: {
    zIndex: 0,
    // transition:' all .7s ease-in-out',
    // transform: 'scaleY(1)',
    backgroundColor: 'blue',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'left',
    backgroundColor: '#f3f3f3',
    height: '200px',
    alignItems: 'center',
    paddingRight: '5px',
    // '&:hover': {
    //   zIndex:-10
    // }
  },
  cardOneRightImageItem: {
    maxHeight: '120px',
    width: '120px',
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip3.png" }) {
        childImageSharp {
          fixed(width: 114, height: 180) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div style={{ height: '100%' }}>
      <Grid
        container
        style={{
          backgroundColor: '#f3f3f3',
          height: '100%',
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={4} className={classes.cardText}>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                height="180" width="115"
                className={classes.imageHide}
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
              />
              <img height="180" width="115" className={classes.imageHide} src={data.file.childImageSharp.fixed.src} alt="Компонент 1" />
            </picture>
            {/* <img src={equip3} className={classes.imageHide} alt="Компонент 3" /> */}
          </div>
        </Grid>
        <Grid item xs={8} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <NumberRed style={{ fontSize: '25px', paddingTop: '10px' }}>03.</NumberRed>
            <Headings2 style={{ fontSize: '20px' }}>WGT приёмник</Headings2>
            <span>Осуществляет раздачу закрытой беспроводной связи, в зоне действия которой считываются метки.</span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <ModalMobile>
          <b>Беспроводной шлюз</b> обеспечивает: <br />
          • Организацию безопасной беспроводной сети станции типа «mesh». <br />
          • Подключение компонентов ForeFuel к автоматизации станции.
          <br />
          • Использование 802.15.4 (Zigbee) на частоте 2,4ГГц.
          <br />
          • Высокую доступность, надежность и отказоустойчивость.
          <br /> 
          • Дистанционное обслуживание и обновление прошивок для всех ForeFuel компонентов.
        </ModalMobile>
      </Backdrop>
    </div>
  );
};
