import React from "react";

export const Video = () => {
  const [windowWidht, setWindowWidht] = React.useState(1280);

  React.useEffect(() => {
    setWindowWidht(window.innerWidth);
  }, []);

  let width = "85%";
  let height = "720px";

  if (windowWidht < 720) {
    width = "100%";
    height = "220px";
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 70,
      }}
    >
      <iframe
        width={width}
        height={height}
        src="https://www.youtube.com/embed/reEfTm6xJ2Y"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};
