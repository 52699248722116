import React, { useContext } from "react";
import { Container, HeadingsRed } from "components/common";
import { Headings } from "components/common";
import { RedLine } from "components/common";
import {
  Wrapper,
  IntroWrapper,
  Slides,
  TitleDownload,
  ButtonRed,
  ImageWrapper,
  Helper,
} from "./styles";
import map from "assets/illustrations/movementmap.png";
import arrow from "assets/illustrations/arrow.svg";
import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import ModalWindow from "../ModalWindow";
import DownloadForm from "../DownloadForm";

import Arrow from "../../../assets/svg_plugin/triangle.svg";

const useStyles = makeStyles((theme) => ({
  slider_wrapper: {
    position: "relative",
    "@media (min-aspect-ratio: 17/10) and (max-height: 740px)": {
      margin: "auto",
      width: "80%",
    },
  },
  slider: {
    margin: "5px 0",
    width: "100%",
    overflow: "hidden",

    "& .slick-list": {
      height: "54.84vw",
      "@media (min-width: 1280px)": {
        height: 702,
      },

      "@media (min-aspect-ratio: 17/10) and (max-height: 720px)": {
        height: "43.87vw",
      },
      "@media (min-aspect-ratio: 17/10) and (max-height: 720px) and (min-width: 1280px)": {
        height: 562,
      },

      "& div": {
        height: "100%",
      },
      "& .slick-track": {
        display: "flex",
      },
    },

    "& .slick-dots": {
      display: "flex !important",
      margin: "auto",
      justifyContent: "center",
      marginTop: "10px",

      "& li": {
        "list-style-type": "none",
        display: "block",
        background: "#E5E5E5",
        borderRadius: "100%",

        width: "1vw",
        height: "1vw",
        maxWidth: 8,
        maxHeight: 8,

        margin: "1vw",
        "@media (min-width: 1280px)": {
          margin: "10px",
        },

        "& button": {
          display: "none",
        },
      },
      "& li.slick-active": {
        background: "#C51B10",
      },
    },
  },
  slide: {
    height: "auto",

    width: "100vw",
    maxWidth: 1280,
    "@media (min-aspect-ratio: 17/10) and (max-height: 720px)": {
      width: "80vw !important",
      maxWidth: 1024,
    },
  },
  nav: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "100%",

    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 960px)": {
      display: "none",
    },
  },
  slider__button_nav: {
    boxSizing: "border-box",

    background: "#C51B0F",
    borderRadius: "1000px",
    border: "none",
    cursor: "pointer",

    transform: "rotate(-90deg)",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    fontWeight: 700,
    lineHeight: 1.5,
    color: "white",

    width: "3.12vw",
    height: "3.12vw",
    padding: "0.78vw",
    fontSize: "1.56vw",
    "@media (min-width: 1280px)": {
      width: 40,
      height: 40,
      padding: 10,
      fontSize: 20,
    },
    "@media (max-width: 767px)": {
      fontSize: 16,
    },
  },
  mirror: { transform: "rotate(90deg)" },
}));

export const MovementMap = () => {
  const classes = useStyles();

  const [modalActive, setModalActive] = React.useState(false);

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "movementmap.png" }) {
        childImageSharp {
          fixed(width: 1500) {
            src
            srcWebp
          }
        }
      }
      allFile(
        filter: { relativePath: { glob: "presentation/*" } }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 1280)
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.gatsbyImageData
  );

  const [slider, setSlider] = React.useState(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.slider,
    dotsClass: "slick-dots slick-thumb",
  };

  function downloadPresentation() {
    const anchor = document.createElement("a");
    anchor.href = "presentation.pptx";
    anchor.target = "_blank";
    anchor.click();
  }

  return (
    // <StaticQuery
    // query={}
    // render={data => (
    <Wrapper id="movementmap">
      <IntroWrapper as={Container}>
        {/* <Headings>
          <RedLine />
          Как работает <HeadingsRed>ORPAK</HeadingsRed>?
        </Headings> */}

        <div id="presentation" className={classes.slider_wrapper}>
          <Slider ref={setSlider} {...settings}>
            {images.map((img, i) => (
              <GatsbyImage
                image={img}
                alt={`slide_${i}`}
                className={classes.slide}
                imgStyle={{ width: "100%", height: "100%" }}
              />
            ))}
          </Slider>

          <div className={classes.nav}>
            <button
              onClick={() => slider.slickPrev()}
              className={classes.slider__button_nav}
            >
              <Arrow />
            </button>

            <button
              onClick={() => slider.slickNext()}
              className={classes.slider__button_nav + " " + classes.mirror}
            >
              <Arrow />
            </button>
          </div>
        </div>

        <TitleDownload>
          Узнайте подробности в презентации продукта
        </TitleDownload>
        <ButtonRed onClick={() => setModalActive(true)}>
          Скачать презентацию
        </ButtonRed>
        <ModalWindow
          active={modalActive}
          setActive={setModalActive}
          info={<DownloadForm close={() => setModalActive(false)} />}
        />

        {/* <ImageWrapper>
          <picture>
            <source
              srcSet={data.file.childImageSharp.fixed.srcWebp}
              type="image/webp"
            />
            <img src={data.file.childImageSharp.fixed.src} alt="Карта" />
          </picture>
          <img src={map} alt="Уменьшение потери топлива" />
        </ImageWrapper>
        
        <Helper>
          Потяните влево
          <img src={arrow} alt="Уменьшение потери топлива" />
        </Helper> */}
      </IntroWrapper>
    </Wrapper>
    // )}

    // />
  );
};
