import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  HeadingsRed,
  NumberRed,
  Headings2,
  ButtonMore,
  RedLine,
} from "../../../common";
import Modal from "../Modal";
import equip3_modal from "../../../../assets/illustrations/equip3_modal.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: "450px",
    height: "640px",
    marginRight: "15px",
  },
  cardText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardImage: {
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    height: "250px",
    display: "flex",
    justifyContent: "center",
  },
  cardHide: {
    maxWidth: "400px",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: 0,
    transition: "all .7s ease-in-out",
    fontWeight: "300",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
    alignItems: "left",
    backgroundColor: "#f3f3f3",
    fontFamily: "Roboto, Helvetica, sans-serif",
    "&:hover": {
      // display: 'none',
      zIndex: 1,
    },
  },
  imageHide: {
    margin: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    transform: "scaleY(1)",
    transition: " all .7s ease-in-out",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      // display: 'none',
      opacity: 0,
      zIndex: -10,
    },
  },
  cardOneRightImage: {
    // position: 'absolute',
    width: "100%",
    maxHeight: "400px",
    transition: " all .7s ease-in-out",
    transform: "scaleY(1)",
    backgroundColor: "#f3f3f3",
    paddingLeft: "35px",
    "&:hover": {
      // opacity: 0,
      zIndex: -10,
    },
  },
  cardOneRightImageItem: {
    maxHeight: "400px",
    width: "100%",
  },
  modalImage: {
    paddingTop: 50,
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip3.png" }) {
        childImageSharp {
          fixed(width: 185, height: 240) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <Grid
        container
        style={{
          backgroundColor: "#f3f3f3",
          padding: "10px 0 10px 10px",
          clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
        }}
      >
        <Grid item xs={6} className={classes.cardText}>
          <NumberRed>03.</NumberRed>
          <Headings2>WGT приёмник</Headings2>
        </Grid>
        <Grid item xs={6} className={classes.cardImage}>
          <div className={classes.cardHide}>
            <span>
              Осуществляет раздачу закрытой беспроводной связи, в зоне действия
              которой считываются метки.
            </span>
            <ButtonMore onClick={handleToggle}>Подробнее</ButtonMore>
          </div>
          <div className={classes.cardOneRightImage}>
            <picture>
              <source
                height="240"
                width="185"
                srcSet={data.file.childImageSharp.fixed.srcWebp}
                type="image/webp"
              />
              <img
                height="240"
                width="185"
                src={data.file.childImageSharp.fixed.src}
                alt="Компонент 2"
              />
            </picture>
            {/* <img src={equip3} className={classes.imageHide} alt="Компонент 3"/> */}
          </div>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Modal>
          <RedLine />
          <Headings2 style={{ fontSize: "34px" }}>WGT приёмник</Headings2>
          <span>
            Осуществляет раздачу закрытой беспроводной связи, в зоне действия
            которой считываются метки.
          </span>
          {/* <img
            src={equip3_modal}
            alt="equip_modal"
            className={classes.modalImage}
          ></img> */}
          <br />
          <br />
          <b>Беспроводной шлюз</b> обеспечивает:
          <br />
          <br />
          • Организацию безопасной беспроводной сети станции типа «mesh». <br />
          • Подключение компонентов ForeFuel к автоматизации станции.
          <br />
          • Использование 802.15.4 (Zigbee) на частоте 2,4ГГц.
          <br />
          • Высокую доступность, надежность и отказоустойчивость.
          <br />• Дистанционное обслуживание и обновление прошивок для всех
          ForeFuel компонентов.
        </Modal>
      </Backdrop>
    </div>
  );
};
