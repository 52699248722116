import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { useStaticQuery, graphql } from 'gatsby';
import equip7 from '../../../../../assets/illustrations/equip7.png';
import { ButtonMore, Container, HeadingsRed, NumberRed, Headings2 } from '../../../../common';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
  },
  cardHide: {
    paddingRight: '10px',
    maxWidth: 'auto',
    height: '280px',
    [`@media (max-width: 450px)`]: {
      height: 'auto',
    },
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    transition: 'all .7s ease-in-out',

    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontWeight: '400',
    fontSize: '14px',

    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      zIndex: 1,
    },
  },
  imageHide: {
    height: '80%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    transform: 'scale(-1, 1)',
  },
  modalWindow: {
    backgroundColor: '#f3f3f3',
    color: 'black',
    padding: '30px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    zIndex: 1000,
    width: '300px',
  },
  modalWindowWrapper: {
    // wigth: '100%',
    paddintTop: '100px',
    marginLeft: '30px',
    marginRight: '30px',
  },

  cardOneRightImage: {
    zIndex: 0,
    paddingRight: '15px',
    // transition:' all .7s ease-in-out',
    // transform: 'scaleY(1)',

    backgroundColor: 'blue',
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'left',
    backgroundColor: '#f3f3f3',
    height: '200px',
    alignItems: 'center',
    // '&:hover': {
    //   zIndex:-10
    // }
  },
}));

export default () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "equip7.png" }) {
        childImageSharp {
          fixed(width: 145, height: 160) {
            src
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <Grid
      container
      style={{
        backgroundColor: '#f3f3f3',
        height: '100%',
        clipPath: ` polygon(0% 0px, 0px 0%, calc(100% - 35px) 0%, 100% 35px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 35px 100%, 0 calc(100% - 35px)   )`,
      }}
    >
      <Grid item xs={4} className={classes.cardText}>
        <div className={classes.cardOneRightImage}>
          <picture>
            <source width="145" height="160" className={classes.imageHide} srcSet={data.file.childImageSharp.fixed.srcWebp} type="image/webp" />
            <img  width="145" height="160" className={classes.imageHide} src={data.file.childImageSharp.fixed.src} alt="Компонент 2" />
          </picture>
          ;{/* <img src={equip7} className={classes.imageHide} alt="Компонент 7"/> */}
        </div>
      </Grid>
      <Grid item xs={8} className={classes.cardImage}>
        <div className={classes.cardHide}>
          <NumberRed style={{ fontSize: '25px', paddingTop: '10px' }}>07.</NumberRed>
          <Headings2 style={{ fontSize: '20px' }}>Програмное обеспечение Fleet Head Office</Headings2>
          <span>
            Комплексное программное обеспечение для управления заправками, авторизации транспортных средств
            редактирования баз данных, установки лимитов, генерации отчётов о движении топлива
          </span>
        </div>
      </Grid>
    </Grid>
  );
};
